import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Restore +',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, PRODUCT DEVELOPMENT',
    description: `Restore CDB was developed to provide inflammation and pain relief to individuals leading active lifestyles. We used a pop of color to give the brand a modern aesthetic that would be recognizable without using the logo in the branding and advertising campaigns. Our goal was to design a wellness brand that would set itself apart in an industry of muted colors and light palettes. We got our inspiration from luxury fashion labels and fitness brands to give the customer a product they feel could match their lifestyle.

    The brand is launching soon.`,
    link: 'restorecbd.co',
  },
  caseId: 'restore',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image11.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
